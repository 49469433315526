import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Test from "./components/test";
import ThankYou from "./components/ui/Thankyou";
import Main from "./main/Main";
import Pricing from "./components/ui/Pricing";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/thankyou/:name" component={ThankYou} />
        <Route exact path="/test" component={Test} />
        <Route path="/pricing" component={Pricing} />
      </Switch>
    </Router>
  );
}

export default App;
