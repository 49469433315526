import { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import priceCss from "./price.module.css";

const PriceCard = ({ props }) => {
  const [gallery, setGallery] = useState(false);
  const [testimony, setTestimony] = useState(false);

  const listGroup = priceCss.listGroup;
  const subTitle = priceCss.subTitle;
  const PriceTitle = priceCss.PriceTitle;
  const price = priceCss.price;

  const CheckGallery = () => {
    if (props.gallery === "Gallery") {
      setGallery(false);
    } else {
      setGallery(true);
    }
  };

  const CheckTestimony = () => {
    if (props.testimony === "SEO") {
      setTestimony(false);
    } else {
      setTestimony(true);
    }
  };

  useEffect(() => {
    //   CheckGallery();
    CheckTestimony();
  }, []);

  console.log(props.gallery);
  return (
    <>
      <Card.Title className={PriceTitle}>{props.packageTitle}</Card.Title>
      <Card.Title className={price}>{props.packagePrice}</Card.Title>
      <ListGroup as="ol" numbered>
        <ListGroup.Item className={listGroup}>
          <div className="ms-2 me-auto">
            <div className={subTitle}>{props.subPriceTitle}</div>
            <ul>
              <li>{props.header}</li>
              <li>{props.about}</li>

              <li>
                {props.products} <br />
              </li>
              {props.gallery && <li>{props.gallery}</li>}
              <li>{props.contact}</li>
              <hr />
              <li>{props.launchTime}</li>

              {/* {props.testimony && <li>{props.testimony}</li>} */}
            </ul>
          </div>
        </ListGroup.Item>

        <ListGroup.Item className={listGroup}>
          <div className="ms-2 me-auto">
            <div className="fw-bold">Domain</div>
            Free for 1 Year
          </div>
        </ListGroup.Item>
      </ListGroup>
      <br />
    </>
  );
};
export default PriceCard;
