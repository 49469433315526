import React from "react";
import { Button, Image } from "react-bootstrap";
import headerImage from "../../images/new-mobile1.jpg";
import style from "./HeaderStyle.module.css";
import { Helmet } from "react-helmet";

const Header = () => {
  const servicesText = style.servicesText;
  const titleWrapper = style.titleWrapper;
  const btnHeader = style.btnHeader;
  const title = style.title;
  const headerImg = style.headerImg;
  return (
    <>
      <Helmet>
        <title>Designing Glory</title>
        <meta
          name="description"
          content="Professional, custom, modern websites and applications with tailored consulting services and personal brand design"
        />
        <link rel="canonical" href="https://designinglory.com" />
      </Helmet>
      <div className={style["styles"]}>
        <div>
          <section>
            <div className={titleWrapper}>
              <aside>
                <div>
                  <div className={servicesText}>
                    <div className={title}>Designing Glory LLC</div>
                    <h1>Professional custom websites</h1>
                    <Image
                      width="100%"
                      src={headerImage}
                      className={headerImg}
                    />
                  </div>
                  <Button
                    variant="success"
                    className={btnHeader}
                    href="https://calendly.com/juliannedrager/website-app-consoltation"
                    target="blank"
                  >
                    GET QUOTE
                  </Button>
                </div>
              </aside>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Header;
