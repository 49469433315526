import Header from "./Header";

//json data for price data
export const priceData = [
  {
    id: 1,
    packageTitle: (
      <>
        Basic but beautiful <br />{" "}
        <span style={{ textTransform: "capitalize", fontWeight: "normal" }}>
          Nothing fancy, Just Get Me Online
        </span>
      </>
    ),
    packagePrice: "$799",
    subPriceTitle: "1 Pg Mobile Design",
    header: "Header",
    about: "About",
    contact: "Contact",
    products: "Product/Service",
    launchTime: "1-2 weeks launch after design approval",
  },
  {
    id: 2,
    packageTitle: (
      <>
        Professional Period <br />{" "}
        <span style={{ textTransform: "capitalize", fontWeight: "normal" }}>
          Professional and modern will do
        </span>
      </>
    ),
    packagePrice: "$1499",
    subPriceTitle: "1 Pg Mobile Design",
    header: "Header",
    about: "About",
    contact: "Contact",
    gallery: "Gallery/Video",
    products: "Product/Service",
    launchTime: "2-3 weeks launch after design approval",
  },
  {
    id: 3,
    packageTitle: (
      <>
        Elite and Neat <br />{" "}
        <span style={{ textTransform: "capitalize", fontWeight: "normal" }}>
          I want jaw-dropping design, I want to stand out
        </span>
      </>
    ),
    packagePrice: "$2199",
    subPriceTitle: "1 Pg Mobile Design",
    header: "Header",
    about: "About",
    contact: "Contact",
    gallery: "Gallery/Video",
    products: "Product/Service",
    launchTime: "2-4 weeks launch after design approval",
  },

  {
    id: 4,
    packageTitle: (
      <>
        Database Integration <br />{" "}
        <span style={{ textTransform: "capitalize", fontWeight: "normal" }}>
          I need to capture registrants and store their data
        </span>
      </>
    ),
    packagePrice: "$4199",
    subPriceTitle: "1 Pg Elite Mobile Design",
    header: "Header",
    about: "About",
    contact: "Contact",
    gallery: "Gallery/Video",
    products: "Product/Service",
    launchTime: "4-6 weeks launch after design approval",
  },
];
