import { Button, Card, Container, ListGroup } from "react-bootstrap";
import PriceCard from "./PriceCard";
import { priceData } from "./PriceData";
import priceCss from "./price.module.css";
import Nav from "./Nav";

const Pricing = () => {
  const pricePackageMap = priceData.map((price, index) => (
    <PriceCard key={index} props={price} />
  ));

  const priceContainer = priceCss.priceContainer;
  const priceContainerTop = priceCss.priceContainerTop;
  const listGroup = priceCss.listGroup;
  const priceCardTop = priceCss.priceCardTop;
  const priceCard = priceCss.priceCard;
  const subTitle = priceCss.subTitle;
  const background = priceCss.background;
  const h2Title = priceCss.h2Title;
  const hrStyle = priceCss.hrStyle;

  return (
    <>
      <Nav />
      <div className={background}>
        <div style={{ margin: "5vw 0 0 0" }}>
          <h2 className={h2Title}>DESIGN PACKAGES</h2>
        </div>

        <section>
          <Card
            style={{ backgroundColor: "transparent", border: "transparent" }}
          >
            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
            <Card.Body style={{ height: "height: 62vw" }}>
              <div className={priceContainerTop}>
                <Card className={priceCardTop}>
                  <ListGroup as="ol" numbered>
                    <ListGroup.Item className={listGroup}>
                      <div className="ms-2 me-auto">
                        <div className={subTitle}>Logo (Up to 3 revisions)</div>
                        <b>$150</b>
                      </div>{" "}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>

                <Card className={priceCardTop}>
                  <ListGroup as="ol" numbered>
                    <ListGroup.Item className={listGroup}>
                      <div className="ms-2 me-auto">
                        <div className={subTitle}>
                          Copy Per Section (written content users will read)
                        </div>
                        <b>$100</b>
                      </div>{" "}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>

                <Card className={priceCardTop}>
                  <ListGroup.Item className={listGroup}>
                    <div className="ms-2 me-auto">
                      <div className={subTitle}>maintenance (hosting cost)</div>
                      <b>$50/mo</b>
                    </div>
                  </ListGroup.Item>
                </Card>
                <Card className={priceCardTop}>
                  <ListGroup.Item className={listGroup}>
                    <div className={subTitle}>Minor updates:</div>
                    <b>$20-40/hr</b>
                    <hr className={hrStyle} />
                    <div className={subTitle}>Major updates:</div>
                    <b>$65-70/hr</b>
                  </ListGroup.Item>
                </Card>
              </div>
            </Card.Body>
          </Card>

          <br />

          <div className={priceContainer}>
            <Card className={priceCard}>
              <Card.Body>{pricePackageMap[0]}</Card.Body>
            </Card>
            <br />
            <Card className={priceCard}>
              <Card.Body>{pricePackageMap[1]}</Card.Body>
            </Card>
            <br />
            <Card className={priceCard}>
              <Card.Body>{pricePackageMap[2]}</Card.Body>
            </Card>
            <br />
            <Card className={priceCard}>
              <Card.Body>{pricePackageMap[3]}</Card.Body>
            </Card>
            <br />
          </div>
        </section>
      </div>
    </>
  );
};
export default Pricing;
