import React, { Suspense } from "react";
import style from "./MainStyles.module.css";
const Navigation = React.lazy(() => import("../components/ui/Nav"));
const Header = React.lazy(() => import("../components/ui/Header"));
const Footer = React.lazy(() => import("../components/ui/Footer"));
const PartThree = React.lazy(() => import("../components/PartThree"));
const Icons = React.lazy(() => import("../components/ui/Icons"));

const Main = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <section>
        <div className={style["nav-bar-top"]}></div>
        <Navigation />
      </section>
      <Header />
      <div className={style["bodyHeight"]}>
        <Icons />
        <PartThree />
      </div>
      <Footer />
    </Suspense>
  );
};

export default Main;
